import url from '@/resources/_url'

const head = {
  title: {
    inner: 'TrueCoffee Go Application'
  },
  meta: [
    {
      id: 'description',
      name: 'description',
      content: 'TrueCoffee GO Application พร้อมบริการที่ตอบโจทย์ไลฟ์สไตล์ทันสมัยของคนรุ่นใหม่! สั่งง่าย จ่ายสะดวก รวดเร็ว เสิร์ฟถึงที่ แบบ one step services'
    },
    {
      id: 'og:url',
      name: 'og:url',
      content: `${url.mainUrl}/application`
    },
    {
      id: 'og:title',
      name: 'og:title',
      content: 'TrueCoffee Go Application'
    },
    {
      id: 'og:description',
      name: 'og:description',
      content: 'TrueCoffee GO Application พร้อมบริการที่ตอบโจทย์ไลฟ์สไตล์ทันสมัยของคนรุ่นใหม่! สั่งง่าย จ่ายสะดวก รวดเร็ว เสิร์ฟถึงที่ แบบ one step services'
    },
    {
      id: 'og:image',
      name: 'og:image',
      content: '/meta-image.png'
    }
  ],
  link: [
    {
      id: 'canonical',
      rel: 'canonical',
      href: `${url.mainUrl}/application`
    }
  ]
}

export default head
