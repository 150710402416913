<template>
  <section class="container application">
    <div class="row">
      <div class="col-12 head">
        <TitleHead title="Application"/>
      </div>
    </div>

    <article class="row app">
      <div class="col-12 col-md-6 content">
        <img
          class="icon-app"
          src="@/assets/icons/logo-app.svg"
          alt="Icon truecoffee go">
        <h3>TRUECOFFEE <span>GO</span></h3>
        <p>DIGITALISED FOOD AND BEVERAGE</p>
        <div class="link-app">
          <a
            v-for="(app, i) in apps"
            :key="i"
            :id="i === 0 ? 'downloadAppiOSTop' : 'downloadAppAndroidTop'"
            :data-name="app.data_name"
            :href="app.link"
            target="blank" >
            <img
              :src="require(`@/assets/images/${app.image_name}`)"
              :alt="app.alt">
          </a>
        </div>
      </div>

      <div class="col-12 col-md-6 mock-app-home">
        <img
          src="@/assets/images/mock-app-home.png"
          alt="Mockup truecoffee go application">
      </div>
    </article>

    <article class="row order">
      <div class="col-12 col-md-6 content">
        <h3>Order in-app</h3>
        <p>Whether it’s to pick-up at our kiosks or get it delivered to you, enjoy your favourite quality beverages and food conveniently.</p>
        <div class="icons">
          <img
            src="@/assets/icons/icon-cup-outline.svg"
            alt="Icon cup outline truecoffee go">
          <img
            src="@/assets/icons/ic-delivery.svg"
            alt="Icon cup envelope outline truecoffee go"
            style="height: 42px;" >
        </div>
      </div>

      <div class="col-12 col-md-6 mock">
        <img
          src="@/assets/images/mock-app-order.png"
          alt="">
      </div>
    </article>

    <article class="row justify-content-center experience-small">
      <hooper
        :itemsToShow="1.5"
        :centerMode="true" >
        <slide
          v-for="(experience, i) in experiences"
          :key="i" >
          <div class="card-silde">
            <div class="box-title">
              <span>{{ experience.head.text }}</span>
              <img
                :src="require(`@/assets/icons/${experience.head.icon}`)"
                class="icon-head CUSTOMISE" >
            </div>

            <div
              class="card-experience-small">
              <img
                :src="require(`@/assets/images/${experience.imageName}`)"
                :alt="experience.detail">
              <h4>{{ experience.title }}</h4>
              <p>{{ experience.detail }}</p>
            </div>
          </div>
        </slide>
      </hooper>

      <!-- <div class="container row wrap-title">
        <div class="col-5 col-md-auto box-title">
          <span>CUSTOMISE</span>
          <img
            src="@/assets/icons/ic-customise.svg"
            class="icon-head CUSTOMISE" >
        </div>

        <div class="col-4 col-md-auto box-title">
          <span>PAY</span>
          <img
            src="@/assets/icons/ic-pay.svg"
            class="icon-head" >
        </div>

        <div class="col-5 col-md-auto box-title">
          <span>EARN</span>
          <img
            src="@/assets/icons/ic-earn.svg"
            class="icon-head" >
        </div>

        <div class="col-4 col-md-auto box-title">
          <span>REDEEM</span>
          <img
            src="@/assets/icons/ic-redeem.svg"
            class="icon-head" >
        </div>
      </div>

      <div
        v-for="(experience, i) in experiences"
        :key="i"
        class="col-12 col-md-3 card-experience">
        <img
          :src="require(`@/assets/images/${experience.imageName}`)"
          :alt="experience.detail">
        <h4>{{ experience.title }}</h4>
        <p>{{ experience.detail }}</p>
      </div> -->
    </article>

    <article class="row justify-content-center experience-medium">
      <div class="container row wrap-title">
        <div class="col-5 col-md-auto box-title">
          <span>CUSTOMISE</span>
          <img
            src="@/assets/icons/ic-customise.svg"
            class="icon-head CUSTOMISE" >
        </div>

        <div class="col-4 col-md-auto box-title">
          <span>PAY</span>
          <img
            src="@/assets/icons/ic-pay.svg"
            class="icon-head" >
        </div>

        <div class="col-5 col-md-auto box-title">
          <span>EARN</span>
          <img
            src="@/assets/icons/ic-earn.svg"
            class="icon-head" >
        </div>

        <div class="col-4 col-md-auto box-title">
          <span>REDEEM</span>
          <img
            src="@/assets/icons/ic-redeem.svg"
            class="icon-head" >
        </div>
      </div>

      <div
        v-for="(experience, i) in experiences"
        :key="i"
        class="col-12 col-md-3 card-experience">
        <img
          :src="require(`@/assets/images/${experience.imageName}`)"
          :alt="experience.detail">
        <h4>{{ experience.title }}</h4>
        <p>{{ experience.detail }}</p>
      </div>
    </article>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import head from './head'
import apps from '@/resources/apps.json'
const TitleHead = () => import('@/components/TitleHead')

export default {
  components: {
    Hooper,
    Slide,
    TitleHead
  },

  head: head,

  data () {
    return {
      apps: apps,
      experiences: [
        {
          head: {
            text: 'CUSTOMISE',
            icon: 'ic-customise.svg'
          },
          title: 'CUSTOMISE YOUR ORDER',
          detail: 'Create your own taste with toppings and sweetness levels',
          imageName: 'mock-app-preference.png'
        },
        {
          head: {
            text: 'PAY',
            icon: 'ic-pay.svg'
          },
          title: 'PAY WITH YOUR PHONE',
          detail: 'Link e-wallet or any payment system for ways to pay',
          imageName: 'mock-app-more-point.png'
        },
        {
          head: {
            text: 'EARN',
            icon: 'ic-earn.svg'
          },
          title: 'MORE POINTS!',
          detail: 'For every 25 baths spend get 1 True Point to enjoy many privileges',
          imageName: 'mock-app-pay.png'
        }
        // {
        //   head: {
        //     text: 'REDEEM',
        //     icon: 'ic-redeem.svg'
        //   },
        //   title: 'USE POINTS!',
        //   detail: '1 True Point = 1 Baht, applicable to all at TrueCoffee GO',
        //   imageName: 'mock-app-rewards.png'
        // }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.application {
  padding-top: 60px;

  .head {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }
  }

  article {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #CCC;
  }

  article:last-child {
    border: none;
  }

  .app {
    padding-bottom: 50px;

    @media screen and (min-width: 768px) {
      margin-top: -80px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 768px) {
        align-items: flex-end;
      }

      .icon-app {
        margin: 20px 0;
        height: 77px;

        @media screen and (min-width: 768px) {
          margin: 0;
        }
      }

      h3 {
        height: fit-content;
        font-family: 'DB Ozone X Bd';
        font-size: 30px;

        @media screen and (min-width: 768px) {
          text-align: right;
          font-size: 58px;
        }

        span {
          font-family: 'DB Ozone X Bd';
          color: $color-main;
        }
      }

      p {
        font-size: 18px;
        color: #666;

        @media screen and (min-width: 768px) {
          margin-top: -4px;
          text-align: right;
          line-height: 1;
          font-size: 24px;
        }
      }

      .link-app {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
          flex-direction: row;
        }

        a {
          img {
            margin-top: 10px;
            width: 165px;

            @media screen and (min-width: 768px) {
              margin: 15px 0 0 20px;
              width: auto;
              height: 50px;
            }
          }
        }
      }
    }

    .mock-app-home {
      margin: 25px 0;
      display: flex;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }

      img {
        width: 260px;
        height: 100%;
      }
    }
  }

  .order {
    display: flex;
    padding: 50px 0;

    @media screen and (min-width: 768px) {
      flex-direction: row-reverse;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 36px;

      @media screen and (min-width: 768px) {
        align-items: flex-start;
      }

      h3 {
        font-family: 'DB Ozone X Bd';
        font-size: 30px;

        @media screen and (min-width: 768px) {
          font-size: 42px;
        }
      }

      p {
        margin: 15px 0;
        font-size: 18px;
        text-align: center;

        @media screen and (min-width: 768px) {
          margin: 10px 0;
          width: 300px;
          font-size: 22px;
          text-align: left;
        }
      }

      .icons {
        display: flex;
        align-items: center;

        img {
          margin: 0 15px;
          height: 38px;
        }

        @media screen and (min-width: 768px) {
          img {
            margin: 20px 30px 0 0;
          }
        }
      }
    }

    .mock {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      @media screen and (min-width: 768px) {
        justify-content: flex-end;
        margin-top: 0;
      }

      img {
        width: 260px;
        height: 100%;
      }
    }
  }

  .experience-small {
    padding-top: 22px;
    padding-bottom: 22px;

    @media screen and (min-width: 768px) {
      display: none;
    }

    .hooper {
      height: fit-content;
    }

    .hooper-slide {
      height: fit-content;
    }

    .card-silde {
      .box-title {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: 'DB Ozone X Bd';
          font-size: 30px;
        }

        .icon-head {
          margin-left: 12px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .card-experience-small {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: 450px;
      }

      h4,
      p
      {
        width: 230px;
        text-align: center;
      }

      h4 {
        font-family: 'DB Ozone X Bd';
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .experience-medium {
    display: none;
    padding: 50px 0;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    .wrap-title {
      justify-content: center;
      // padding-left: 42px;
      // padding-right: 42px;

      @media screen and (min-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .box-title {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 8px;
      padding-left: 0px;
      padding-right: 0px;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
      }

      span {
        margin-top: 6px;
        font-family: 'DB Ozone X Bd';
        font-size: 22px;

        @media screen and (min-width: 768px) {
          margin-top: 0;
          margin-right: 12px;
        }
      }

      // span::after {
      //   padding-left: 12px;
      //   padding-right: 12px;
      //   content: '•';

      //   @media screen and (min-width: 768px) {
      //     display: none;
      //   }
      // }

      .icon-head {
        // margin-left: 12px;
        // margin-left: -30px;
        width: auto;
        height: 32px;

        @media screen and (min-width: 768px) {
          margin-left: 0;
        }
      }

      .CUSTOMISE {
        height: 28px;
      }
    }

    .box-title:last-child {
      .icon-head {
        margin-left: 0;
      }

      span::after {
        display: none;
        content: ''
      }
    }

    .head {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;

      // h3 {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: flex-start;
      //   width: fit-content;

      //   @media screen and (min-width: 768px) {
      //     flex-direction: row;
      //     align-items: center;
      //   }

      //   span {
      //     display: flex;
      //     flex-direction: row-reverse;
      //     justify-content: flex-start;
      //     align-items: center;
      //     padding: 12px 0;
      //     font-family: 'DB Ozone X Bd';
      //     font-size: 24px;

      //     @media screen and (min-width: 768px) {
      //       flex-direction: row-reverse;
      //       padding: 0 18px;
      //       font-size: 42px;
      //     }
      //   }

      //   .icon-head {
      //     margin: 0 18px;
      //     width: auto;
      //     height: 38px;
      //   }
      // }
    }

    .card-experience {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 40px 40px;
      margin: 40px 40px;

      h4 {
        font-family: 'DB Ozone X Bd';
        font-size: 30px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }

      p {
        padding: 6px 0;
        width: 186px;
        font-size: 18px;
        text-align: center;

        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }

      img {
        width: 260px;
      }
    }
  }
}
</style>
